import React, { useState, useEffect, useRef, useCallback } from "react";
import { motion, useAnimationControls, useInView } from "framer-motion";
import { IconButton } from "@mui/material";
import { Box } from "@mui/system";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { alpha } from "@mui/system";
import { useTheme } from '@mui/material/styles';

const defaultOptions = {
    width: "100%",
    height: "300px",
    svgHeight: "200",
    svgWidth: "400",
    // svgViewbox: "0 0 50 50",
    viewBoxHeightClip: 200, // 50,
    viewBoxWidthClip: 100, // 190,
    // clipPathD: "M25 0c13.807 0 25 11.193 25 25S38.807 50 25 50 0 38.807 0 25 11.193 0 25 0z",
    circClipPathD: "m 49.999911,25.000187 a 25.000002,25 0 0 1 -25.000002,25 25.000002,25 0 0 1 -25.000002460083,-25 A 25.000002,25 0 0 1 24.999909,1.8692017e-4 25.000002,25 0 0 1 49.999911,25.000187 Z",
    clipPathD: "m 49.999911,25.000187 c 0,6.903559 -9.546673,8.507749 -13.464891,12.360531 -3.918218,3.852781 -4.631551,12.639469 -11.535111,12.639469 -6.90356,0 -2.500429,-3.888019 -7.02454,-8.412129 C 13.451259,37.063948 -9.3460083e-5,31.903746 -9.3460083e-5,25.000187 -9.3460083e-5,18.096628 10.390877,13.70741 14.309096,9.8546279 18.227314,6.0018465 18.096349,1.8689458e-4 24.999909,1.8692017e-4 31.903469,1.8694575e-4 28.362406,6.5753943 32.886517,11.099505 c 4.52411,4.52411 17.113394,6.997123 17.113394,13.900682 z",
    // clipPathD: "m 49.999911,25.000187 c 0,6.903559 -9.546673,8.507749 -13.464891,12.360531 -3.918218,3.852781 -4.631551,12.639469 -11.535111,12.639469 -6.90356,0 -2.500429,-3.888019 -7.02454,-8.412129 C 13.451259,37.063948 -9.3460083e-5,31.903746 -9.3460083e-5,25.000187 -9.3460083e-5,18.096628 10.390877,13.70741 14.309096,9.8546279 18.227314,6.0018465 18.096349,1.8689458e-4 24.999909,1.8692017e-4 31.903469,1.8694575e-4 28.362406,6.5753943 32.886517,11.099505 c 4.52411,4.52411 17.113394,6.997123 17.113394,13.900682 z"

    viewBoxHeight: 200, // 50,
    viewBoxWidth: 100, // 190,
    // pathD: "M20 15.5c-1.25 0-2.45-.2-3.57-.57-.35-.11-.74-.03-1.02.24l-2.2 2.2c-2.83-1.44-5.15-3.75-6.59-6.58l2.2-2.21c.28-.27.36-.66.25-1.01C8.7 6.45 8.5 5.25 8.5 4c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1 0 9.39 7.61 17 17 17 .55 0 1-.45 1-1v-3.5c0-.55-.45-1-1-1zM12 3v10l3-3h6V3h-9z",
    pathD: "m 18.5,16.5 a 2,2 0 0 0 -2,2 16,16 0 0 0 15,15 2,2 0 0 0 2,-2 v -4 l -5,-2 L 27,28 a 11,11 0 0 1 -5,-5 l 2.5,-1.5 -2,-5 z m 8.794922,0.179688 6,6 a 6,6 0 0 0 -6,-6 z m 0,4 2,2 a 2,2 0 0 0 -2,-2 z",
    //clipPathD: "M 25.658106,32.565936 8.3795725,32.878139 0.43643121,17.530447 -8.4732112,2.7228992 0.84670938,-11.829909 9.2156005,-26.949659 26.478662,-26.154775 43.757196,-26.466978 51.700337,-11.119286 60.60998,3.6882613 51.290059,18.241069 42.921168,33.36082 Z",
    morphClipPathD: "m 50.018183,25.202265 c 0,6.903559 -11.336601,20.428515 -11.336601,20.428515 0,0 -6.75984,4.571485 -13.663399,4.571485 -6.903559,0 -21.6919403,-4.571485 -21.6919403,-4.571485 0,0 -3.30805995,-13.524956 -3.30805995,-20.428515 0,-6.903559 3.30805985,-14.926824 3.30805985,-14.926824 0,0 14.7883814,-10.07317628 21.6919404,-10.07317621 6.903559,7e-8 13.663399,10.07317621 13.663399,10.07317621 0,0 11.336601,8.023265 11.336601,14.926824 z",
    dUnionPath2: "m 33.802734,10.476562 6,6 a 6,6 0 0 0 -6,-6 z m -1.443359,4.609376 3.195313,3.234374 A 3.1949192,3.2342818 0 0 0 32.359375,15.085938 Z M 18.5,16.5 a 2,2 0 0 0 -2,2 16,16 0 0 0 15,15 2,2 0 0 0 2,-2 v -4 l -5,-2 L 27,28 a 11,11 0 0 1 -5,-5 l 2.5,-1.5 -2,-5 z m 12.037109,1.355469 2,2 a 2,2 0 0 0 -2,-2 z",
    wrapStyle: {
        position: "relative",
        marginLeft: 8,
        marginRight: 8,
    },
    svgAnimate: {
        rotateX: 360, x: [ 0, 20, 0 ]
    },
    svgTransition: {
        delay: 2,
        type: "spring",
        damping: 1,
        stiffness: 10,
    },
    svgInitial: {
        rotateY: 0, x: 0
    },
    pathAnimate: { rotateZ: 360 },
    pathTransition: {
        ease: "easeInOut",
        repeat: Infinity,
        repeatDelay: 5,
        duration: 2
    },
    strokeWidthClip: "14",

    pathStyle: {},

}

const AnimateSvg = ({ options = defaultOptions }) => {
    const {
        height,
        svgWidth,
        svgHeight,
        viewBoxHeight,
        viewBoxWidth,
        pathD,
        dUnionPath2,
        clipPathD,
        circClipPathD,
        viewBoxHeightClip,
        viewBoxWidthClip,
        wrapStyle,
        svgInitial,
        pathTransition,
        strokeWidthClip,
        pathStyle,
    } = options

    // const isMounted = useRef(false);
    const [ replay, setReplay ] = useState(true);
    const refWrap = useRef(null)
    const isInView = useInView(refWrap)

    const theme = useTheme()
    const ctlSvg = useAnimationControls()
    const ctlPathShape1 = useAnimationControls()
    const ctlPathCirc = useAnimationControls()
    const ctlClipPathCirc = useAnimationControls()
    const ctlUnionPath1 = useAnimationControls()
    const ctlUnionPath2 = useAnimationControls()
    const ctlGCircClipping = useAnimationControls()
    const ctlUseGShapeClipping = useAnimationControls()
    const ctlUseGCircClipping = useAnimationControls()
    const ctlUseUnionPath2Solo = useAnimationControls()



    const pathShiftToClipCentreX = (viewBoxWidthClip - viewBoxWidth) / 2
    const pathShiftToClipCentreY = (viewBoxHeightClip - viewBoxHeight) / 2
    const pathStyleCorr = { ...pathStyle, x: pathShiftToClipCentreX, y: pathShiftToClipCentreY }

    const colorFill = theme.palette.error.main
    const colorFillFrom = alpha(colorFill, 0.2)
    const colorFillTo = alpha(colorFill, 1)


    const stopAllCtl = useCallback(() => {
        ctlSvg.stop()
        ctlPathShape1.stop()
        ctlPathCirc.stop()
        ctlClipPathCirc.stop()
        ctlUnionPath1.stop()
        ctlUnionPath2.stop()
        ctlUseGShapeClipping.stop()
        ctlUseGCircClipping.stop()
        ctlUseUnionPath2Solo.stop()
    }, [ ctlSvg, ctlPathShape1, ctlPathCirc, ctlClipPathCirc, ctlUnionPath1, ctlUnionPath2, ctlUseGShapeClipping, ctlUseGCircClipping, ctlUseUnionPath2Solo ])

    // useEffect(() => {
    //     isMounted.current = true;
    //     return () => {
    //         isMounted.current = false;
    //     }
    // }, [])

    useEffect(() => {
        let isMount = true
        if (!isInView) {
            stopAllCtl()
            return
        }
        const seq = async () => {

            isMount && await ctlSvg.start({
                rotateX: 0, // [ null, 45, 0 ],
                x: 0, // [ 0, 20, 0 ],
                transition: {
                    delay: 2,
                    type: "spring",
                    damping: 1,
                    stiffness: 10,
                }
            })

            isMount && await ctlGCircClipping.start({
                x: 100,
                transition: {
                    // duration: 1,
                    delay: 1,
                    type: "spring",
                    damping: 1,
                    stiffness: 10,
                }
            })

            isMount && await ctlUseGShapeClipping.start({
                x: 40,
                transition: {
                    // duration: 1,
                    delay: 1,
                    type: "spring",
                    damping: 1,
                    stiffness: 10,
                }
            })

            isMount && await ctlPathShape1.start({
                rotateZ: [ null, 0, 360 ],
                x: [ null, 200, 0 ],
                transition: {
                    duration: 3,
                    delay: 1,
                    // type: "spring",
                    // damping: 1,
                    // stiffness: 10,
                }
            })

            isMount && await ctlUseGCircClipping.start({
                x: 40,
                transition: {
                    // duration: 1,
                    type: "spring",
                    damping: 1,
                    stiffness: 10,
                }
            })
            isMount && await ctlPathCirc.start({
                rotateZ: [ null, 360, 360 ], x: [ null, -200, 0 ],
                transition: {
                    duration: 3,
                    delay: 1,
                    // type: "spring",
                    // damping: 1,
                    // stiffness: 10,
                }
            })
            isMount && await ctlClipPathCirc.start({
                skewX: [ 0, 30, -30, 0 ],
                transition: {
                    duration: 3,
                }
            })
            isMount && await ctlUnionPath1.start({
                scale: [ 1, 1.5, 1.5, 1 ],
                fill: [ colorFillFrom, colorFillTo, colorFillTo, colorFillFrom ],
                rotateZ: 360,
                transition: {
                    duration: 4,
                }
            })
            isMount && await ctlUnionPath2.start({
                scale: [ 1, 1.5, 1, 5, 1 ],
                fill: [ colorFillFrom, colorFillTo, colorFillTo, colorFillFrom ],
                rotateZ: 360,
                transition: {
                    duration: 4,
                }
            })

            isMount && await ctlUseGShapeClipping.start({
                rotateZ: [ null, 360, 360 ], x: [ null, 200, 40 ],
                transition: {
                    duration: 3,
                    delay: 1,
                    // type: "spring",
                    // damping: 1,
                    // stiffness: 10,
                }
            })
            isMount && await ctlUseGCircClipping.start({
                rotateZ: [ null, 360, 360 ],
                x: [ null, 200, 40 ],
                // rotateZ: [ 0, 360, 360 ],
                // x: [ 0, 200, 40 ],
                // x: 40,
                transition: {
                    duration: 3,
                    // times: [ 0, 0.5, 1 ],
                    delay: 1,
                    // // type: "tween",
                    // type: "spring",
                    // damping: 1,
                    // stiffness: 10,
                }
            })
            isMount && await ctlUseUnionPath2Solo.start({
                y: [ "0%", "-100%", "100%", "0%", "0%", "-100%", "0%" ],
                x: [ 0, 0, -50, 50, 0, 0, 0 ],
                transition: {
                    duration: 10,
                    delay: 1,
                    // type: "spring",
                    // damping: 1,
                    // stiffness: 10,
                }
            })
            isMount && await ctlUseGCircClipping.start({
                x: 0,
                transition: {
                    delay: 1,
                    type: "spring",
                    damping: 1,
                    stiffness: 10,
                    duration: 1
                }
            })
            isMount && await ctlUseGShapeClipping.start({
                x: 0,
                transition: {
                    delay: 1,
                    type: "spring",
                    damping: 1,
                    stiffness: 10,
                    // duration: 1
                }
            })
            isMount && await ctlGCircClipping.start({
                x: 0,
                transition: {
                    delay: 1,
                    type: "spring",
                    damping: 1,
                    stiffness: 10,
                    // duration: 1
                }
            })
            isMount && ctlSvg.start({
                rotateX: 90,
            })
        }
        seq()
        return () => {
            isMount = false
            stopAllCtl()
        }
    }, [ isInView, replay, colorFillFrom, colorFillTo, ctlClipPathCirc, ctlGCircClipping, ctlPathCirc, ctlPathShape1, ctlSvg,
        ctlUnionPath1, ctlUnionPath2, ctlUseGCircClipping, ctlUseGShapeClipping, ctlUseUnionPath2Solo, stopAllCtl ])

    return (
        <Box
            ref={refWrap}
            sx={{
                ...wrapStyle,
                maxWidth: '100%',
                height: height,

                "& button": {
                    bgcolor: "primary.dark",
                    color: "primary.main",
                    zIndex: 1,
                }
            }}
        >
            <motion.svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                viewBox={`0 0 ${viewBoxHeightClip} ${viewBoxWidthClip}`}
                height={svgHeight}
                initial={svgInitial}
                animate={ctlSvg}
                transition={{
                    delay: 2,
                    type: "spring",
                    damping: 1,
                    stiffness: 100,
                }}
                width={svgWidth}
                style={{
                    maxWidth: 'inherit',
                    width: '100%',
                    originX: "100%",
                    rotateX: 90
                }}
                key="svg1"
            >
                <defs>
                    <motion.path
                        id="circShape1"
                        d={clipPathD}
                        animate={ctlPathShape1}
                        style={{
                        }}
                        key="circShape1"
                    />
                    <motion.path
                        id="circPath"
                        d={circClipPathD}
                        animate={ctlPathCirc}
                        key="circPath"
                    />
                    <clipPath id="circShape1Clip"  >
                        <use xlinkHref="#circShape1" id="useClipShape1" />
                    </clipPath>
                    <motion.clipPath
                        id="circClipPath"
                        animate={ctlClipPathCirc}
                        transition={{
                            duration: 2,
                            repeat: Infinity,
                        }}
                        key="circClipPath"
                    >
                        <use xlinkHref="#circPath" id="useCircClip" />
                    </motion.clipPath>
                    <linearGradient id="linear" x1="0%" y1="0%" x2="100%" y2="100%">
                        <motion.stop
                            stopColor="#2B00FF"
                            animate={{
                                stopColor: [
                                    "#0055FF",
                                    "#FFF9DA",
                                    "#E7FFF7",
                                    "#FFC6A8",
                                    "#FF7744",
                                    "#F3F2F2"
                                ]
                            }}
                            transition={{

                                repeat: Infinity,
                                ease: "linear",
                                duration: 8
                            }}
                            offset="25%"
                        />
                        <motion.stop
                            stopColor="#0055FF"
                            animate={{
                                stopColor: [
                                    "#0055FF",
                                    "#FFF9DA",
                                    "#FFC6A8",
                                    "#FF7744",
                                    "#2B00FF"
                                ]
                            }}
                            transition={{
                                repeat: Infinity,
                                ease: "linear",
                                duration: 8
                            }}
                            offset="50%"
                        />
                        <motion.stop
                            stopColor="#D4504C"
                            animate={{
                                stopColor: [ "#FFF9DA", "#E7FFF7", "#0055FF" ]
                            }}
                            transition={{
                                repeat: Infinity,
                                ease: "linear",
                                duration: 8
                            }}
                            offset="75%"
                        />
                        <motion.stop
                            stopColor="#FF7744"
                            animate={{
                                stopColor: [ "#D4504C", "#2B00FF", "#E7FFF7", "#FFF9DA" ]
                            }}
                            transition={{
                                repeat: Infinity,
                                ease: "linear",
                                duration: 8
                            }}
                            offset="100%"
                        />
                    </linearGradient>
                </defs>
                <motion.g id="gCircClipping"
                    animate={ctlGCircClipping}
                >
                    <use
                        fill="transparent"
                        stroke="url(#linear)"
                        strokeWidth={strokeWidthClip}
                        clipPath="url(#circClipPath)"
                        xlinkHref="#circPath"
                    />
                    <motion.path
                        id="unionPath"
                        animate={ctlUnionPath1}
                        transition={pathTransition}
                        d={pathD}
                        style={{
                            ...pathStyleCorr,
                            fill: colorFillFrom,
                        }}
                        stroke="red"
                        key="pathD1"
                    />
                </motion.g>
                <g id="gShapeClipping"                >
                    <use
                        fill="transparent"
                        stroke="url(#linear)"
                        strokeWidth={strokeWidthClip}
                        clipPath="url(#circShape1Clip)"
                        xlinkHref="#circShape1"
                    />
                    <motion.path
                        id="unionPath2"
                        animate={ctlUnionPath2}
                        transition={pathTransition}
                        d={dUnionPath2}
                        style={{ ...pathStyleCorr, fill: colorFillFrom, }}
                        stroke="red"
                        key="dUnionPath21"
                    />
                </g>
                <motion.use
                    key="useGShapeClipping"
                    xlinkHref="#gShapeClipping"
                    id="useGShapeClipping"
                    animate={ctlUseGShapeClipping}
                    transition={{
                        duration: 2,
                        repeat: Infinity,
                    }}
                    style={{}}
                />
                <motion.use
                    key="useGCircClipping"
                    xlinkHref="#gCircClipping"
                    id="useGCircClipping"
                    animate={ctlUseGCircClipping}
                    // transition={{
                    //     duration: 2,
                    //     repeat: Infinity,
                    // }}
                    style={{
                        rotateZ: 0, x: 0,
                    }}
                />
                <motion.use
                    key="useUnionPath2Solo"
                    xlinkHref="#unionPath2"
                    id="useUnionPath2Solo"
                    // initial={{
                    //     // rotateZ: 0, 
                    //     y: "0%", x: 0
                    // }}
                    animate={ctlUseUnionPath2Solo}
                    style={{
                        // rotateZ: 0,
                        y: "0%", x: 0,
                    }}
                // transition={{
                //     duration: 16,
                //     repeat: Infinity,
                //     repeatDelay: 2
                // }}
                />

            </motion.svg>

            <IconButton aria-label="replay" size="large"
                onClick={() => {
                    const isMount = true
                    if (window) {
                        setReplay(!replay);
                        window.setTimeout(() => isMount && setReplay(true), 600);
                    }
                }}
            >
                <AutorenewIcon />
            </IconButton>
        </Box>
    );
};

export default AnimateSvg;